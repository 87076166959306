import StatsLeaderElement from './statsLeaderElement';

export default class StatsLeaderResponse {
  id = 0

  /* eslint-disable camelcase */
  max_shot_speed: StatsLeaderElement = new StatsLeaderElement()

  max_sprint_speed: StatsLeaderElement = new StatsLeaderElement()

  max_ball_count: StatsLeaderElement = new StatsLeaderElement()
  /* eslint-enable camelcase */
}
