































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import LeaderboardElementSM from '@/components/LeaderboardElementSM.vue';
import Player from '@/types/player';

@Component({
  components: {
    LeaderboardElementSM,
  },
})
export default class Leaderboard extends Vue {
  @Prop() private playerlist!: Array<Player>
}
