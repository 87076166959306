













import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import Top3 from '@/components/Top3.vue'; // @ is an alias to /src
import Top4to10 from '@/components/Top4to10.vue';
import Flop90 from '@/components/Flop90.vue';
import StatsLeaders from '@/components/StatsLeaders.vue';
import PlayerListResponseElement from '@/types/playerListResponseElement';
import Player from '@/types/player';

@Component({
  components: {
    Top3,
    Top4to10,
    Flop90,
    StatsLeaders,
  },
})
export default class Leaderboard extends Vue {
  playerList: Array<Player> = []

  mounted() : void {
    this.loadPlayers();

    setInterval(this.loadPlayers, 60000);
  }

  loadPlayers(): void {
    this.playerList = [];
    axios.get(`${process.env.VUE_APP_FABINHO_URL}/allstarplayer/list/`, {
      params: {
        tournament: this.$route.params.id,
      },
    }).then((response) => {
      response.data.map((player: PlayerListResponseElement) => {
        this.playerList.push(new Player(player));
        this.playerList.sort(
          (player1: Player, player2: Player) => player1.ranking - player2.ranking,
        );
        return player;
      });
    });
  }
}
