















































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import Player from '@/types/player';

@Component
export default class LeaderboardElement extends Vue {
    @Prop() private player!: Player;

    private profilepicture = '';

    mounted(): void {
      this.player.updateFromIndex()
        .then(() => {
          this.profilepicture = this.player.profilePic;
          this.player.name = this.player.name.substring(0, 10);
        });
    }
}
