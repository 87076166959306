



































import { Component, Prop, Vue } from 'vue-property-decorator';
import LeaderboardElement from '@/components/LeaderboardElement.vue';
import Player from '@/types/player';

@Component({
  components: {
    LeaderboardElement,
  },
})
export default class Leaderboard extends Vue {
  @Prop() private playerlist!: Array<Player>
}
