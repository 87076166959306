import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Leaderboard from '../views/Leaderboard.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:id',
    name: 'Leaderboard',
    component: Leaderboard,
  },
  {
    path: '/card/:id',
    name: 'Card',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "card" */ '../views/Card.vue'),
  },
  {
    path: '/card/sm/:id',
    name: 'CardSM',
    component: () => import(/* webpackChunkName: "card-sm" */ '../views/CardSM.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
