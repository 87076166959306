





























import { Component, Prop, Vue } from 'vue-property-decorator';
import Player from '@/types/player';
import Top3ElementSM from '@/components/Top3ElementSM.vue';
import Top3ElementMD from '@/components/Top3ElementMD.vue';
import Top3ElementLG from '@/components/Top3ElementLG.vue';

@Component({
  components: {
    Top3ElementSM,
    Top3ElementMD,
    Top3ElementLG,
  },
})
export default class Top3 extends Vue {
  @Prop() private playerlist!: Array<Player>;
}
