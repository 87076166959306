



























































import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import StatsLeaderResponse from '@/types/statsLeaderResponse';

@Component
export default class Leaderboard extends Vue {
  statsLeader: StatsLeaderResponse = new StatsLeaderResponse();

  shotSpeedNickname = '';

  sprintSpeedNickname = '';

  ballCountNickname = '';

  mounted() : void {
    axios.get(`${process.env.VUE_APP_FABINHO_URL}/allstar/detail/`, {
      params: {
        id: this.$route.params.id,
      },
    }).then((response) => {
      [this.statsLeader] = response.data.results;
      axios.get(`${process.env.VUE_APP_OKOCHA_URL}/profile/allstar_identity/`, {
        params: {
          user_id: this.statsLeader.max_shot_speed.owner_okocha_id,
        },
      }).then((maxShotSpeedResponse) => {
        if (maxShotSpeedResponse.data.results.length > 0) {
          if (maxShotSpeedResponse.data.results[0].nickname) {
            this.shotSpeedNickname = maxShotSpeedResponse.data.results[0].nickname;
          }
        }
      });
      axios.get(`${process.env.VUE_APP_OKOCHA_URL}/profile/allstar_identity/`, {
        params: {
          user_id: this.statsLeader.max_ball_count.owner_okocha_id,
        },
      }).then((maxBallCountResponse) => {
        if (maxBallCountResponse.data.results.length > 0) {
          if (maxBallCountResponse.data.results[0].nickname) {
            this.ballCountNickname = maxBallCountResponse.data.results[0].nickname;
          }
        }
      });
      axios.get(`${process.env.VUE_APP_OKOCHA_URL}/profile/allstar_identity/`, {
        params: {
          user_id: this.statsLeader.max_sprint_speed.owner_okocha_id,
        },
      }).then((maxSprintSpeedResponse) => {
        if (maxSprintSpeedResponse.data.results.length > 0) {
          if (maxSprintSpeedResponse.data.results[0].nickname) {
            this.sprintSpeedNickname = maxSprintSpeedResponse.data.results[0].nickname;
          }
        }
      });
    });
  }
}
