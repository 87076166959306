




























































import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Player from '@/types/player';

@Component
export default class Top3ElementSM extends Vue {
  @Prop() private player!: Player;

  private profilepicture = '';

  mounted(): void {
    axios.get(`${process.env.VUE_APP_OKOCHA_URL}/profile/allstar_identity/`, {
      params: {
        user_id: this.player.ownerOkochaId,
      },
    }).then((response) => {
      if (response.data.results.length > 0) {
        this.profilepicture = response.data.results[0].profile_pic;
        if (response.data.results[0].nickname) {
          this.player.name = response.data.results[0].nickname;
        }
      }
    });
  }
}
