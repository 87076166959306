








































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Player from '@/types/player';

@Component
export default class LeaderboardElementSM extends Vue {
    @Prop() private player!: Player;

    mounted(): void {
      this.player.updateFromIndex();
    }
}
