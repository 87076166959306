import algoliasearch from 'algoliasearch';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import PlayerListResponseElement from './playerListResponseElement';

// Implement algolia here.
const client = algoliasearch('GOFWC3DH7J', '3e7c78939cdff0ad7d3122699ac65235', {
  responsesCache: createInMemoryCache(),
  requestsCache: createInMemoryCache({ serializable: false }),
});
const index = client.initIndex('user_index');

export default class Player {
    id: number

    ranking: number

    formerRanking: number

    ownerOkochaId: number

    name: string

    cumulatedDistance: number

    cumulatedBallCount: number

    maxShotSpeed: number

    cumulatedScore: number

    sessionCount: number

    profilePic: string

    constructor(player: PlayerListResponseElement) {
      this.id = player.id;
      this.ranking = player.ranking;
      this.formerRanking = player.former_ranking;
      this.ownerOkochaId = player.owner_okocha_id;
      this.name = player.name;
      this.cumulatedDistance = player.cumulated_distance;
      this.cumulatedBallCount = player.cumulated_ball_count;
      this.maxShotSpeed = player.max_shot_speed;
      this.cumulatedScore = player.cumulated_score;
      this.sessionCount = player.session_count;
      this.profilePic = '';
    }

    updateFromIndex() : Promise<void> {
      return index.getObject(this.ownerOkochaId.toString(), {
        attributesToRetrieve: ['nickname', 'profile_pic_url'],
      }).then((algoliaResponse) => {
        this.name = (algoliaResponse as any).nickname;  // eslint-disable-line
        this.profilePic = (algoliaResponse as any).profile_pic_url;  // eslint-disable-line
      }).catch(() => {
        console.log('Player not found on index.');
      });
    }
}
